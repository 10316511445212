import vuetify from '@/plugins/vuetify';

export function defaultServicePlan() {
  return {
    type: '',
    title: '',
    subtitle: '',
    description: '',
    monthlyPrice: null,
    annualPrice: null,
    discount: 0,
    featuresHeading: '',
    features: [],
    choosePlanText: vuetify.framework.lang.t('$vuetify.servicePlan.choosePlanText'),
    backgroundImagePath: '',
    disabled: true,
    additionalText: '',
  };
}

export function defaultRegisterServicePlan() {
  return {
    level: '',
    levelText: '',
    title: '',
    periods: null,
    premium: null,
  };
}

export function defaultProfileServicePlan() {
  return {
    annualPrice: null,
    discount: 0,
    monthlyPrice: null,
    owned: false,
    premium: false,
    title: '',
    nextLevelName: '',
    canUpgrade: '',
    canDowngrade: '',
    nextLevelText: '',
    nextLevelDateStart: '',
  };
}
