import isEmpty from 'lodash/isEmpty';
import vuetify from '@/plugins/vuetify';
import groupBy from 'lodash/groupBy';
import forOwn from 'lodash/forOwn';
import mapValues from 'lodash/mapValues';
import head from 'lodash/head';
import { mapGetters } from 'vuex';
import { transactionStatus, transactionType } from '@/modules/user-profile/tools/enums';
import { Store } from '../../../store';
import { defaultRegisterServicePlan, defaultServicePlan, defaultProfileServicePlan } from '../tools/mappers';
import {
  servicePlans as servicePlansEnum,
  servicePlansTable as servicePlansTableEnum,
  submodules,
  submoduleActions,
  levels,
  servicePlanLevelsMapping,
  levelToRole,
  levelIDToType,
  PLAN_TRANSITION_ACTION,
} from '../tools/enums';
import { descStringArraySorter } from '../tools/sorters';
import { calculateDiscount } from '../tools/helpers';

const SERVICE_PLAN_LEVELS = process.env.VUE_APP_SERVICE_PLAN_LEVELS.split(',');

// TODO: move to method, or other place to verify dynamically, instead of static checking at beginning
const HAS_SERVICE_PLAN = Store.hasModule('servicePlan');
const servicePlanGetters = HAS_SERVICE_PLAN
  ? mapGetters('servicePlan', ['isDataReady', 'plansLoading'])
  : {};

export default {
  data() {
    return {
      timesServicePlansHasBeenUpdated: 0,
    };
  },

  asyncComputed: {
    servicePlans: {
      get() {
        return HAS_SERVICE_PLAN ? Store.dispatch(`${submodules.servicePlan}/${submoduleActions.plansRequest}`) : null;
      },
      default() {
        return null;
      },
      watch: ['timesServicePlansHasBeenUpdated'],
    },
  },

  computed: {
    transaction() {
      return this.$store.state.userProfile.transaction;
    },

    hasServicePlan() {
      return HAS_SERVICE_PLAN;
    },

    servicePlanLevelsDesc() {
      return [...SERVICE_PLAN_LEVELS].sort(descStringArraySorter);
    },

    ...servicePlanGetters,
    ...mapGetters(['currentProfile']),

    planListItems() {
      // If data is not ready then return default object
      if (!this.isDataReady || !this.servicePlans) {
        return SERVICE_PLAN_LEVELS.map(() => defaultServicePlan());
      }

      return SERVICE_PLAN_LEVELS.map((level) => {
        const isPremium = level !== levels.LEVEL0;

        const predefinedDiscount = servicePlansEnum[level].discount;

        let discount = 0;
        if (predefinedDiscount) {
          discount = predefinedDiscount;
        } else if (isPremium) {
          discount = calculateDiscount(this.servicePlans[`${level}-M`], this.servicePlans[`${level}-Y`]);
        }

        return {
          level,
          ...defaultServicePlan(),
          ...servicePlansEnum[level],
          annualPrice: isPremium ? this.servicePlans[`${level}-Y`].amount : null,
          monthlyPrice: isPremium ? this.servicePlans[`${level}-M`].amount : null,
          type: isPremium ? this.servicePlans[`${level}-Y`].type : this.servicePlans[level].type,
          discount,
        };
      });
    },

    planListItemsTable() {
      // If data is not ready then return default object
      if (!this.isDataReady || !this.servicePlans) {
        return SERVICE_PLAN_LEVELS.map(() => defaultServicePlan());
      }

      return SERVICE_PLAN_LEVELS.map((level) => {
        const isPremium = level !== levels.LEVEL0;

        const predefinedDiscount = servicePlansTableEnum[level].discount;

        let discount = 0;
        if (predefinedDiscount) {
          discount = predefinedDiscount;
        } else if (isPremium) {
          discount = calculateDiscount(this.servicePlans[`${level}-M`], this.servicePlans[`${level}-Y`]);
        }

        return {
          level,
          ...defaultServicePlan(),
          ...servicePlansTableEnum[level],
          annualPrice: isPremium ? this.servicePlans[`${level}-Y`].amount : null,
          monthlyPrice: isPremium ? this.servicePlans[`${level}-M`].amount : null,
          type: isPremium ? this.servicePlans[`${level}-Y`].type : this.servicePlans[level].type,
          discount,
        };
      });
    },

    registerServicePlans() {
      // If data is not ready then return default object
      if (!this.isDataReady || !this.servicePlans) {
        return SERVICE_PLAN_LEVELS.map(() => defaultRegisterServicePlan());
      }

      return SERVICE_PLAN_LEVELS.map((level) => {
        const isPremium = level !== levels.LEVEL0;
        let periods = [];
        if (isPremium) {
          const predefinedDiscount = servicePlansEnum[level].discount;

          let discount = 0;
          if (predefinedDiscount) {
            discount = predefinedDiscount;
          } else if (isPremium) {
            discount = calculateDiscount(this.servicePlans[`${level}-M`], this.servicePlans[`${level}-Y`]);
          }

          periods = [
            {
              id: this.servicePlans[`${level}-M`].id,
              paymentTitle: vuetify.framework.lang.t('$vuetify.servicePlan.monthlyPayment'),
              periodTitle: vuetify.framework.lang.t('$vuetify.servicePlan.month'),
              amount: this.servicePlans[`${level}-M`].amount,
            },
            {
              id: this.servicePlans[`${level}-Y`].id,
              paymentTitle: vuetify.framework.lang.t('$vuetify.servicePlan.annualPayment'),
              periodTitle: vuetify.framework.lang.t('$vuetify.servicePlan.year'),
              amount: this.servicePlans[`${level}-Y`].amount,
              discount,
            },
          ];
        }

        return {
          level,
          levelText: servicePlansEnum[level].levelText,
          title: servicePlansEnum[level].title,
          periods,
          premium: isPremium,
        };
      });
    },

    highestOwnedServicePlanLevel() {
      return this.servicePlanLevelsDesc.find((level) => {
        return this.currentProfile.hasRole(levelToRole[level]);
      });
    },

    currentServicePlan() {
      return this.allOwned.length ? this.allOwned[0] : null;
    },

    currentServicePlanType() {
      return this.currentServicePlan?.type;
    },

    profileServicePlans() {
      // If data is not ready then return default object
      if (!this.isDataReady || !this.servicePlans || isEmpty(this.transaction) || !this.currentProfile) {
        return SERVICE_PLAN_LEVELS.map(() => defaultProfileServicePlan());
      }

      const hightestOwnedServicePlanLevel = this.servicePlanLevelsDesc.find((level) => {
        return this.currentProfile.hasRole(levelToRole[level]);
      });

      const { status, type, dateStart, externalID } = this.transaction;
      const currentServicePlan = Object.values(this.servicePlans).find((plan) => plan.owned);

      return SERVICE_PLAN_LEVELS.map((level) => {
        let nextLevelText;
        let nextLevelDateStart;
        let ownedByRole = false;
        let ownedByRoleDateEnd;

        const isPremium = level !== levels.LEVEL0;
        const { title } = servicePlansEnum[level];

        let owned = false;
        let canUpgrade;
        let canDowngrade;
        if (typeof currentServicePlan === 'object' && currentServicePlan.type === level) {
          const canUpgradeID = currentServicePlan.canUpgrade[0];
          const canDowngradeID = currentServicePlan.canDowngrade[0];
          owned = true;

          if (canUpgradeID) {
            canUpgrade = {
              id: canUpgradeID,
              type: this.servicePlans[canUpgradeID].type,
              title: servicePlanLevelsMapping[canUpgradeID].title,
            };
          }

          if (canDowngradeID) {
            canDowngrade = {
              id: canDowngradeID,
              type: this.servicePlans[canDowngradeID].type,
              title: servicePlanLevelsMapping[canDowngradeID].title,
            };
          }

          if (status === transactionStatus.NEW && type === transactionType.RECURRING && isPremium) {
            const nextLevelName = externalID.split('_').pop();
            nextLevelText = servicePlanLevelsMapping[nextLevelName].levelText;
            nextLevelDateStart = dateStart;
          }
        }

        let canChange;
        if (!isPremium && owned) {
          canChange = [
            { level: levels.LEVEL1, title: servicePlansEnum[levels.LEVEL1].title },
            { level: levels.LEVEL2, title: servicePlansEnum[levels.LEVEL2].title },
          ];
        }

        if (!owned && hightestOwnedServicePlanLevel && level === hightestOwnedServicePlanLevel) {
          ownedByRole = true;
          ownedByRoleDateEnd = this.transaction.dateEnd;
        }

        const predefinedDiscount = servicePlansEnum[level].discount;

        let discount = 0;
        if (predefinedDiscount) {
          discount = predefinedDiscount;
        } else if (isPremium) {
          discount = calculateDiscount(this.servicePlans[`${level}-M`], this.servicePlans[`${level}-Y`]);
        }

        return {
          ...defaultProfileServicePlan(),
          title,
          discount,
          annualPrice: isPremium ? this.servicePlans[`${level}-Y`].amount : null,
          monthlyPrice: isPremium ? this.servicePlans[`${level}-M`].amount : null,
          premium: isPremium,
          owned,
          ownedByRole,
          ownedByRoleDateEnd,
          canUpgrade,
          canDowngrade,
          canChange,
          nextLevelText,
          nextLevelDateStart,
        };
      });
    },

    nextLevelType() {
      if (isEmpty(this.transaction)) return null;

      const {
        status: currentTransactionStatus,
        type: currentTransactionType,
        dateStart: currentTransactionDateStart,
        externalID
      } = this.transaction;
      if (
        currentTransactionStatus === transactionStatus.NEW
        && currentTransactionType === transactionType.RECURRING
      ) {
        const nextLevelID = externalID.split('_').pop();
        return levelIDToType[nextLevelID];
      }
      if (currentTransactionStatus === transactionStatus.VOID && currentTransactionType === transactionType.RECURRING) {
        return levels.LEVEL0;
      }
      return null;
    },

    profileServicePlansNew() {
      if (!this.isDataReady || !this.servicePlans || isEmpty(this.transaction) || !this.currentProfile) {
        // TODO: provide new structure of default
        return SERVICE_PLAN_LEVELS.map(() => defaultProfileServicePlan());
      }

      let nextLevelType;
      const canDowngradeOrUpgrade = [];

      const hightestOwnedServicePlanLevel =
        this.servicePlanLevelsDesc.find((level) => this.currentProfile.hasRole(levelToRole[level]));

      const {
        status: currentTransactionStatus,
        type: currentTransactionType,
        dateStart: currentTransactionDateStart,
        externalID
      } = this.transaction;
      const currentServicePlan = Object.values(this.servicePlans).find((plan) => plan.owned);
      const currentServicePlanType = currentServicePlan ? currentServicePlan.type : undefined;

      if (currentTransactionStatus === transactionStatus.NEW && currentTransactionType === transactionType.RECURRING) {
        const nextLevelID = externalID.split('_').pop();
        nextLevelType = levelIDToType[nextLevelID];
      }

      Object.values(this.servicePlans).forEach(servicePlan => {
        canDowngradeOrUpgrade.push(...servicePlan.canUpgrade);
        canDowngradeOrUpgrade.push(...servicePlan.canDowngrade);
      });

      return SERVICE_PLAN_LEVELS.map((level) => {
        let status;
        let actionAvailable = false;
        const isPremium = level !== levels.LEVEL0;
        const { title, featuresText } = servicePlansEnum[level];
        const active = level === hightestOwnedServicePlanLevel || level === currentServicePlanType;
        const disabled = active;

        if (level === nextLevelType) {
          status = vuetify.framework.lang.t('next plan');
        }

        if (active) {
          status = vuetify.framework.lang.t('current plan');
        }

        if (!active && !isPremium) {
          actionAvailable = true;
        }

        if (!active && canDowngradeOrUpgrade.some(id => id.indexOf(level) > -1)) {
          actionAvailable = true;
        }

        return {
          actionAvailable,
          action: this.actions[level],
          active,
          featuresText,
          premium: isPremium,
          status,
          title,
          disabled,
          monthlyPrice: isPremium ? this.servicePlans[`${level}-M`].amount : null,
          annualPrice: isPremium ? this.servicePlans[`${level}-Y`].amount : null,
        };
      });
    },

    servicePlanDetails() {
      if (!this.isDataReady || !this.servicePlans || isEmpty(this.transaction) || !this.currentProfile) {
        return {};
      }

      let currentTitle;
      let nextLevelText;
      let nextLevelDateStart;
      let nextDateCharge;

      const { status, type, dateStart, dateCharge, externalID } = this.transaction;

      const hightestOwnedServicePlanLevel =
        this.servicePlanLevelsDesc.find((level) => this.currentProfile.hasRole(levelToRole[level]));

      if (hightestOwnedServicePlanLevel) {
        currentTitle =
          `${servicePlansEnum[hightestOwnedServicePlanLevel].levelText} - ${servicePlansEnum[hightestOwnedServicePlanLevel].title}`;
      }

      let nextLevelName;
      if (status === transactionStatus.NEW && type === transactionType.RECURRING) {
        nextLevelName = externalID.split('_').pop();
      }
      if (status === transactionStatus.VOID && type === transactionType.RECURRING) {
        nextLevelName = levels.LEVEL0;
      }
      if (status === transactionStatus.PAID && type === transactionType.RECURRING) {
        nextDateCharge = dateCharge;
      }

      if (nextLevelName) {
        nextLevelText =
          `${servicePlanLevelsMapping[nextLevelName].levelText} - ${servicePlanLevelsMapping[nextLevelName].title}`;
        nextLevelDateStart = dateStart;
      }

      return {
        title: currentTitle,
        nextLevelText,
        nextLevelDateStart,
        nextDateCharge,
      };
    },

    allOwned() {
      return this.servicePlans ? Object.values(this.servicePlans).filter(plan => plan.owned) : [];
    },

    actions() {
      if (!this.allOwned || !this.allOwned.length) {
        return {};
      }

      let planGroupActions = {
        [levels.LEVEL0]: null,
        [levels.LEVEL1]: null,
        [levels.LEVEL2]: null
      };

      this.allOwned.forEach((item) => {
        let isPremium = item.type !== levels.LEVEL0;

        if (!isPremium) {
          // must be bought
          this.$log.debug(item.canUpgrade);
          planGroupActions[levels.LEVEL1] = { type: 'buy', payload: { level: levels.LEVEL1, title: servicePlansEnum[levels.LEVEL1].title } };
          planGroupActions[levels.LEVEL2] = { type: 'buy', payload: { level: levels.LEVEL2, title: servicePlansEnum[levels.LEVEL2].title } };
        } else {
          // can be cancelled
          planGroupActions[levels.LEVEL0] = { type: 'cancel', payload: item.url };
          // maybe can downgrade
          this.$log.debug(item.canDowngrade);
          if (item.canDowngrade.length) {
            item.canDowngrade.forEach((downgradeTarget) => {
              planGroupActions[levelIDToType[downgradeTarget]] = { type: 'downgrade', payload: downgradeTarget };
            });
          }
          // maybe can upgrade
          this.$log.debug(item.canUpgrade);
          if (item.canUpgrade.length) {
            item.canUpgrade.forEach((upgradeTarget) => {
              planGroupActions[levelIDToType[upgradeTarget]] = { type: 'upgrade', payload: upgradeTarget };
            });
          }
        }
      });

      return planGroupActions;
    },

    membershipStatus() {
      if (
        !this.isDataReady
        || !this.servicePlans
        || isEmpty(this.transaction)
        || !this.currentProfile
      ) {
        return undefined;
      }

      let { status, type, externalID, dateCharge } = this.transaction;
      let ownedByRole = this.highestOwnedServicePlanLevel;
      let owned = head(this.allOwned);

      let currentPlan;
      let nextPlan;
      let nextCharge;
      let state = '';

      if (owned?.id === ownedByRole) {
        currentPlan = ownedByRole;
      }
      if (type === transactionType.RECURRING) {
        // have to check transaction
        switch(status) {
          case transactionStatus.NEW:
            state = 'will-change';
            nextPlan = externalID.split('_').pop();
            nextCharge = dateCharge;
            break;
          case transactionStatus.VOID:
            state = 'will-change';
            nextPlan = levels.LEVEL0;
            nextCharge = dateCharge;
            break;
          case transactionStatus.PAID:
            state = 'will-be-paid';
            nextCharge = dateCharge;
            break;
          default:
        }
      }

      return {
        state,
        currentPlan,
        nextPlan,
        nextCharge,
      };
    },

    planGroups() {
      let vm = this;

      let groups = groupBy(this.servicePlans, 'type');

      let output = {};

      if (isEmpty(this.transaction) || !this.isDataReady) {
        return output;
      }

      let currentType = vm.highestOwnedServicePlanLevel || vm.currentServicePlanType;

      let currentTypeIsPremium = currentType && currentType !== levels.LEVEL0;

      let { dateIntervalType } = this.transaction;

      forOwn(groups, (value, key) => {
        let { title, featuresText } = servicePlansEnum[key];
        let isPremium = key !== levels.LEVEL0;
        let isCurrent = key === currentType;

        let nextType = vm.nextLevelType;

        let isNext = key === nextType;
        let status = '';

        if (isNext) {
          status = 'next plan';
        }

        if (isCurrent) {
          status = 'current plan';
        }

        let priceBox = vm.preparePriceBox(value, key);

        let action = vm.prepareAction(key, value, currentType, currentTypeIsPremium, isNext, isCurrent, isPremium, dateIntervalType);

        let orig = value;

        output[key] = {
          title, featuresText, isPremium, isCurrent, isNext, status, priceBox, action, orig,
        };
      });

      return output;
    },
  },

  methods: {
    levelIDToTypeString(id) {
      return levelIDToType[id];
    },
    servicePlan(level) {
      return servicePlansEnum[level];
    },

    preparePriceBox(value, key) {
      if (key === levels.LEVEL0) {
        return 0;
      }
      return mapValues(groupBy(value, 'dateIntervalType'), (a) => head(a));
    },

    prepareAction(key, value, currentType, currentTypeIsPremium, isNext, isCurrent, isPremium, dateIntervalType) {
      if (isNext || isCurrent) {
        return null;
      }

      if (!currentTypeIsPremium) {
        // must be bought on specific premium card
        if (isPremium) {
          return {
            type: PLAN_TRANSITION_ACTION.BUY,
            payload: { level: key, title: servicePlansEnum[key].title }
          };
        }
      } else {
        // may can be cancelled on free card
        if (!isPremium) {
          return { type: PLAN_TRANSITION_ACTION.CANCEL };
        }
        // may be updated on specific premium card
        if (isPremium) {
          let actionType = currentType > key ? PLAN_TRANSITION_ACTION.DOWNGRADE : PLAN_TRANSITION_ACTION.UPGRADE;
          return { type: actionType, payload: {
            type: key, target: `${key}-${dateIntervalType}`
          } };
        }
      }

      return {};
    },
  },
};
