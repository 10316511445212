export const transactionStatus = {
  NEW: 'new',
  PAID: 'paid',
  VOID: 'void',
};

export const transactionType = {
  RECURRING: 'recurring',
  GIFT: 'gift',
};
