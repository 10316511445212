// eslint-disable-next-line import/prefer-default-export
export const calculateDiscount = (monthlyPlan, annualPlan) => {
  const monthlyPrice = monthlyPlan.amount;
  const annualPrice = annualPlan.amount;

  let discount = 0;
  discount = monthlyPrice * 12 - annualPrice;
  if (discount < 0) {
    discount = 0;
  }

  return discount;
};
